import { Boardcode } from "@ttws/boardcode";
import React from "react";
import { RefState } from "../utils";
import { coordCtx, CoordCtx, DocCtx, docCtx, partCtx, PartCtx, SeatCtx, seatCtx, zoneCtx, ZoneCtx } from "./contexts";

export function BoardcodeProvider(props: { doc: RefState<Boardcode.Document>, children: React.ReactNode }) {
    const { doc, children } = props;

    const ctx = React.useMemo(() => new DocCtx(doc.current, doc), [doc]);
    ctx.useDebugConsole();

    return <docCtx.Provider value={ctx}>
        {children}
    </docCtx.Provider>
}

export function EachSeat(props: { children: React.ReactNode }) {
    const { children } = props;

    const seats = React.useContext(docCtx).seatKeys.useState();

    return <>{
        seats.map(seat => <SeatProvider key={seat} seatKey={seat}>
            {children}
        </SeatProvider>)
    }</>
}

function SeatProvider(props: { children: React.ReactNode, seatKey: string }) {
    const { children, seatKey } = props;

    const ctx = React.useContext(docCtx);
    const seat = React.useMemo(() => new SeatCtx(ctx, seatKey), [ctx, seatKey]);
    seat.seat.useSelectByRef();
    seat.zoneKeys.useSelectByRef();

    return <seatCtx.Provider value={seat}>
        {children}
    </seatCtx.Provider>
}

export function EachZone(props: { children: React.ReactNode }) {
    const { children } = props;

    const seat = React.useContext(seatCtx);
    const zoneKeys = seat.zoneKeys.useState();

    return <>{
        zoneKeys.map(zone => <ZoneProvider key={zone} zoneKey={zone}>
            {children}
        </ZoneProvider>)
    }</>
}

function ZoneProvider(props: { zoneKey: string, children: React.ReactNode }) {
    const { zoneKey, children } = props;

    const seat = React.useContext(seatCtx);
    const zone = React.useMemo(() => new ZoneCtx(seat, zoneKey), [seat, zoneKey]);
    zone.zone.useSelectByRef();
    zone.coordCount.useSelectByRef();
    zone.coordRange.useSelectByRef();
    zone.coordKeyMap.useSelectByRef();
    zone.layout.useSelectByRef();
    zone.layoutSize.useSelectByRef();

    return <zoneCtx.Provider value={zone}>
        {children}
    </zoneCtx.Provider>
}

export function EachCoord(props: { children: React.ReactNode }) {
    const { children } = props;

    const zone = React.useContext(zoneCtx);
    const len = zone.coordCount.useState();
    const keymaps = zone.coordKeyMap.useState();

    const elems = [] as React.ReactElement[];
    for (let i = 0; i < len; i++) {
        elems.push(<CoordProvider key={keymaps[i]} coordkey={keymaps[i]} >
            {children}
        </CoordProvider>);
    }

    return <>{elems}</>
}

function CoordProvider(props: { coordkey: string, children: React.ReactNode }) {
    const { coordkey, children } = props;

    const zone = React.useContext(zoneCtx);
    const coord = React.useMemo(() => new CoordCtx(zone, coordkey), [zone]);

    coord.key = coordkey;
    coord.coord.useSelectByState([zone.zone, zone.coordKeyMap, coordkey]);

    coord.code.useSelectByRef();
    coord.count.useSelectByRef();
    coord.pos.useSelectByRef();

    return <coordCtx.Provider value={coord}>
        {children}
    </coordCtx.Provider>
}

export function PartProvider(props: { i: number, children: React.ReactNode }) {
    const { i, children } = props;

    const coord = React.useContext(coordCtx);
    const part = React.useMemo(() => new PartCtx(coord, i), [coord]);
    part.i = i;
    part.part.useSelectByState();

    part.exists.useSelectByRef();
    part.id.useSelectByRef();
    part.type.useSelectByRef();

    const exists = part.exists.useState();

    if (exists) return <partCtx.Provider value={part}>
        {children}
    </partCtx.Provider>
    
    return null;
}