import { RefState } from "./refstate";

export type JSONValue = JSONPrimitive | JSONValue[] | { [key: string]: JSONValue };
type JSONPrimitive = number | string | boolean | null | undefined;

export class EnvRefState<T, T2Base> extends RefState<T>{
    constructor(t: T,
        public readonly key: string,
        readonly provider: <T2 extends T2Base>(key: string, init: T2) => EnvRefState<T2, T2Base>
    ) {
        super(t);
    }

    useChild<T2 extends T2Base>(key: string, init: T2) {
        return this.provider(this.key + '_' + key, init);
    }
}

function createPersistedStates(storage: Storage) {
    const states = new Map<string, EnvRefState<any, JSONValue>>();
    window.addEventListener('beforeunload', function () {
        for (const key of states.keys()) {
            const val = states.get(key)!.current;
            storage.setItem(key, JSON.stringify(val));
        }
    });

    const use = function <T extends JSONValue>(key: string, init: T) {
        if (!states.has(key)) {
            const saved = storage.getItem(key);
            if (saved !== null) {
                try {
                    init = JSON.parse(saved) as T;
                } catch (e) { }
            }
            const refstate = new EnvRefState(init, key, use);
            states.set(key, refstate);
        }

        return states.get(key)! as EnvRefState<T, JSONValue>;
    }
    return use;
}

export const useLocalStorageState = createPersistedStates(localStorage);
export const useSessionStorageState = createPersistedStates(sessionStorage);

const memState = new Map<string, EnvRefState<any, any>>();
export function useMemState<T>(key: string, init: T): EnvRefState<T, any> {
    if (!memState.has(key)) memState.set(key, new EnvRefState(init, key, useMemState));
    return memState.get(key)!;
}