import React from 'react';
import ReactDOM from 'react-dom/client';
import { Errors } from './components';
import { BoardspaceApp } from './components/app';
import { ErrorBoundary } from './utils';

function Hello(){
    return <ErrorBoundary fallback={Errors}>
        <BoardspaceApp />
    </ErrorBoundary>
}

const main = document.createElement('main');
const root = ReactDOM.createRoot(main);
document.body.append(main);
root.render(<Hello />);