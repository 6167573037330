import { Html, Line, Plane } from "@react-three/drei";
import { Object3DProps } from "@react-three/fiber";
import { Boardcode } from "@ttws/boardcode";
import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Object3D } from "three";
import { docCtx, seatCtx, tools, zoneCtx } from "../boardcode";
import { TableRig, useThreeControls } from "../r3f";
import { useRefState, useSelector } from "../utils";
import { lookAtBasis, useCurrentSeatPosition, useCurrentZoneOutline, useSeatBasis } from "./seatbasis";
import { Tool, toolCtx, ToolItem, ToolLayer } from "./toolitem";

export function BoardTableRig(props: { children: React.ReactNode }) {
    const doc = React.useContext(docCtx);
    return <TableRig>
        <docCtx.Provider value={doc}>
            {props.children}
        </docCtx.Provider>
    </TableRig>
}

export type TableSeat = {
    name: string;
    position: [number, number];
    color: string;
    rotation?: number;
    size: [number, number];
}

export function TableSeats(props: { seats: TableSeat[] }) {
    const { seats } = props;

    const controls = useThreeControls();
    React.useEffect(() => {
        if (!seats[0].name || !controls.current) return;
        lookAtBasis(seats[0].name, controls.current);
    }, [seats, controls]);

    return <>{seats.map((seat, i) => <SeatBasis
        key={seat.name || i}
        userData-color={seat.color}
        name={seat.name}
        position-x={seat.position[0] || 0} position-z={seat.position[1] || 0}
        scale-x={seat.size[0]} scale-z={seat.size[1]}
        rotation-y={(seat.rotation || 0) * Math.PI / 180}
    />)}</>
}

export function SeatBasis(props: Object3DProps){
    const name = props.name;
    const ref = React.useRef<Object3D>(null);

    const state = name && useSeatBasis(name);
    React.useEffect(() => {
        if (!state || !ref.current) return;
        ref.current.updateMatrixWorld();
        state.current = ref.current;
        return function(){
            state.current = null;
        }
    }, [state, ref]);

    return <object3D ref={ref} {...props} />
}

export function SeatTools(){
    const controls = useThreeControls();
    const seats = React.useContext(docCtx).seatKeys;
    const keys = seats.useState();
    const children = keys.map((name, i) => ({
        name, icon: 'icon-seat',
        sublabel: <SeatIcon seat={name}/>,
        onTrigger: () => lookAtBasis(name, controls.current)
    })) as ToolItem[];

    return <ToolLayer><toolCtx.Provider value={tools.useChild("focus", '' as string)}>
        <Tool item={{ name: "seat", icon: 'icon-seat', children }} />
    </toolCtx.Provider></ToolLayer>
}

function SeatIcon(props: {seat: string}){
    const basis = useSeatBasis(props.seat).useState();
    const color = basis?.userData.color || 'grey';
    return <i className="iconfont icon-eye" style={{ color }} />;
}

export function ZoneOutline(){
    const style = tools.useChild('stack', '' as string).useChild('border', 'solid' as string).useState();
    if(style === 'none')return null;

    return <ZoneOutlineContent hasLabel={style === 'text'}/>;
}

function ZoneOutlineContent(props: { hasLabel: boolean }) {

    const points = useCurrentZoneOutline().useState();
    if (!points.length) return null;

    const line = <Line points={points} color="black" transparent opacity={0.25} lineWidth={.5} />
    if (!props.hasLabel) return line;

    const [minx, miny, minz] = points[0];
    const [maxx, maxy, maxz] = points[2];
    const cx = minx/2+maxx/2;
    const cy = miny/2+maxy/2;
    const cz = minz/2+maxz/2;
    const sx = minx-maxx;
    const sz = 1;
    const sy = minz-maxz;
    const label = <ZoneLabel pos={[cx, cy, cz]} size={[sx, sy, sz]} />;

    return <>{line}{label}</>
}

function ZoneLabel(props: { pos: Boardcode.Vec3, size: Boardcode.Vec3 }) {
    const zone = React.useContext(zoneCtx);

    const count = useSelector((z, c, k) => {
        if (c != 1) return `${k}(${c})`;

        const coord = z.coords[0];
        return `${k}(${coord.parts.length})`;
    }, zone?.zone, zone?.coordCount, zone?.zoneKey).useState();

    const focus = useRefState(false);
    const off = React.useCallback(() => focus.current = false, [focus]);
    const on = React.useCallback(() => focus.current = true, [focus]);
    const quad = <Plane
        position={props.pos} scale={props.size} rotation-x={-Math.PI / 2}
        onPointerEnter={on}
        onPointerLeave={off}
        onPointerOut={off}
    >
        <meshBasicMaterial transparent opacity={0.1} />
    </Plane>

    if (focus.useState()) {
        return <>
            <Html position={props.pos} center wrapperClass="nopointer">
                <Global />
                <ZoneLabelStyle>{count}</ZoneLabelStyle>
            </Html>
            {quad}
        </>
    }else{
        return quad;
    }

}

export function SeatLabel() {
    const style = tools.useChild('stack', '' as string).useChild('border', 'solid' as string).useState();
    if (style !== 'text') return null;

    return <SeatLabelContent />
}
function SeatLabelContent() {
    const seat = React.useContext(seatCtx).seatKey;
    const color = useSeatBasis(seat).useState()?.userData?.color || 'grey';
    const pos = useCurrentSeatPosition(0.5, 0, 0.5).useState();

    return <Html position={pos} center wrapperClass="nopointer">
        <Global />
        <SeatLabelStyle style={{ color }}>{seat}</SeatLabelStyle>
    </Html>
}

const Global = createGlobalStyle`
    .nopointer{
        pointer-events: none;
        user-select: none;
    }
`;

const SeatLabelStyle = styled.div`
    color: white;
    opacity: .25;
    font-weight: bold;
    font-size: 24mm;
`

const ZoneLabelStyle = styled.div`
    color: white;
    font-size: 4mm;
    background: rgba(0,0,0,.5);
    padding: 0.2em 1.2em;
    border-radius: 1em;
    border: .2mm solid white;
`