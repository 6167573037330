
export type PatternValue = { [key: string]: number | string };
export type PatternContent<T extends PatternValue> = {
    [key in keyof T]: string | T[key] | undefined;
}

export class PatternObject<T extends PatternValue>{
    readonly pattern: RegExp;
    constructor(
        public readonly template: PatternContent<T>,
        pattern: RegExp | string,
        public id: string,
        public readonly defaults?: Partial<T>,
    ) {
        this.pattern = pattern instanceof RegExp ? pattern : new RegExp(pattern);
    }

    get<K extends keyof T>(key: K) {
        const val = this.template[key] || this.defaults && this.defaults[key];
        if(typeof val === 'string')
            return this.id.replace(this.pattern, val as string || '') as T[K];
        return val;
    }

    num<K extends keyof T>(key: K) {
        return parseFloat(this.get(key) as string) || 0 as T[K];
    }

    each(): T | undefined {
        if (!this.defaults) return undefined;

        const result = {} as any;
        for (const key of Object.keys(this.defaults)) {
            if (typeof this.defaults[key] === 'number')
                result[key] = this.num(key);
            else if (typeof this.defaults[key] === 'string')
                result[key] = this.get(key);
        }

        return result as T;
    }

    withId(id: string | undefined) {
        if (!id) return undefined;
        this.id = id;
        return this.each();
    }
}