import React from "react";
import { coordCtx } from "../boardcode";
import { useSelector } from "../utils";
import { PartList } from "./parts";
import { PartRendererMap, PartTemplate } from "./parts/type";
import { useCurrentSeatPosition } from "./seatbasis";

export function CoordParts<M extends PartRendererMap>(props: {
    renderers: M,
    templates: { [key: string]: PartTemplate<M> },
}) {
    const coord = React.useContext(coordCtx);
    const parts = useSelector(coord => coord?.parts || [], coord.coord);
    const face = coord.useCoordState('face');
    const side = coord.useCoordState('side');

    const { renderers, templates } = props;
    const partlist = React.useMemo(
        () => <PartList renderers={renderers} templates={templates} face={face} side={side} parts={parts} />,
        [renderers, templates, face, side, parts]
    );

    const [xpos, zpos] = coord.pos.useState() || [0, 0];
    const position = useCurrentSeatPosition(xpos, 0, zpos).useState();

    return <group position={position}>
        {partlist}
    </group>
}