import { Boardcode, findCoordPos, findCoordRange, findLayoutRange, intentMap, parseCommandCode, pickCoord, pickZone, selectZoneContent, stringifyCoordCode, stringifyLabelCode } from "@ttws/boardcode";
import produce from "immer";
import React from "react";
import { RefState, Selector, useSelector } from "../utils";
import { tools } from "./env";

export interface CommandHandler{
    (cmd: string, apply: (cmd: string) => void): void
}

export class DocCtx {
    constructor(init: Boardcode.Document, public doc = new RefState(init)) { }

    commandHandler?: CommandHandler;

    layout = new Selector(doc => doc.layout, this.doc);
    seatKeys = new Selector(layout => Object.keys(layout.seats), this.layout);

    bc = (cmds: TemplateStringsArray) => {
        for (const one of cmds) {
            this.applyCommand(one);
        }
    };

    useDebugConsole() {
        React.useLayoutEffect(() => {
            const self = window as any;
            const { bc } = this;
            self.bc = bc;
            return function(){
                if (self.bc === bc) delete self.bc;
            }
        }, [this]);
    }

    applyCommand = (code: string) => {
        if (this.commandHandler) this.commandHandler(code, this.run)
        else this.run(code);
    }

    run = (code: string) => {
        let cmd = [] as Boardcode.ParserCommand;
        try {
            cmd = parseCommandCode(code);
        } catch (e) {
            console.error(`unable to compile command "${code}"`);
            throw e;
        }
        const update = produce(this.doc.current,
            doc => intentMap.applyCommand(cmd, doc)
        );

        const self = window as any;
        if (self.bc === this.bc) console.log('[cmd]: ', code);

        this.doc.current = update;
    }
}

export class SeatCtx {
    constructor(public readonly docCtx: DocCtx, public readonly seatKey: string) { }

    seat = new Selector(layout => layout.seats[this.seatKey], this.docCtx.layout);
    zoneKeys = new Selector(seat => Object.keys(seat.zones), this.seat);
    zoneLayoutSizes = new Selector(seat => {
        const sizes = {} as { [key: string]: Boardcode.Vec4 };
        for (const zoneKey of Object.keys(seat.zones)) {
            sizes[zoneKey] = findLayoutRange(seat, seat.zones[zoneKey]);
        }
        return sizes;
    }, this.seat);
}

export class ZoneCtx {
    constructor(public readonly seatCtx: SeatCtx, public readonly zoneKey: string) { }

    zonePath = `${this.seatCtx.seatKey}/${this.zoneKey}`;
    zone = new Selector((doc, path) => doc.content.zones[path], this.seatCtx.docCtx.doc, this.zonePath);

    coordRange = new Selector(zone => findCoordRange(zone), this.zone);
    coordCount = new Selector(zone => zone.coords.length, this.zone);
    coordKeyMap = new Selector(zone => {
        const map = {} as { [key in string | number]: Exclude<string | number, key> };
        for(let i = 0; i < zone.coords.length; i ++){
            const key = this.guessCoordKey(i);
            map[i] = key;
            map[key] = i;
        }
        return map;
    }, this.zone);

    layout = new Selector(seat => seat.zones[this.zoneKey], this.seatCtx.seat);
    layoutSize = new Selector(sizes => sizes[this.zoneKey], this.seatCtx.zoneLayoutSizes);

    private guessCoordKey(i: number) {
        const coord = this.zone.current.coords[i];
        const parts = coord?.parts;
        const last = parts && parts[parts.length - 1];
        if (last && last.serial) {
            return stringifyLabelCode(last);
        }
        return `${i}`;
    }
}

export class CoordCtx {
    constructor(public readonly zoneCtx: ZoneCtx, public key: string) { }

    coord = new Selector((zone, keymap, key) => zone?.coords[keymap[key]], this.zoneCtx.zone, this.zoneCtx.coordKeyMap, this.key);
    count = new Selector(coord => coord?.parts.length, this.coord);
    code = new Selector(coord => coord && stringifyCoordCode(coord.coord), this.coord);
    pos = new Selector((coord, range, layout, size) => {
        return coord && findCoordPos(coord.coord, range, layout, size);
    }, this.coord, this.zoneCtx.coordRange, this.zoneCtx.layout, this.zoneCtx.layoutSize);

    useCoordState(key: string) {
        const zone = this.zoneCtx.zone;
        const coord = this.coord;
        return useSelector((zone, coord, key) => {
            const zp = zone?.zonePartState;
            const cp = coord?.coordPartState;
            return cp && cp[key] || zp && zp[key];
        }, zone, coord, key);
    }

    findPointerAction(drag: [string, number, number], skip?: number, attach?: boolean) {
        if (!drag) return null;

        // find hit zone
        const [seat, xpos, zpos] = drag;
        const doc = this.zoneCtx.seatCtx.docCtx.doc.current;
        const hitZone = pickZone(doc, seat, xpos, zpos);
        if (!hitZone) return null;

        // find hit
        const coordRange = findCoordRange(selectZoneContent(doc, seat, hitZone));
        const style = doc.layout.seats[seat].zones[hitZone];
        const size = this.zoneCtx.seatCtx.zoneLayoutSizes.current[hitZone];
        const hit = pickCoord(xpos, zpos, coordRange, style, size);

        // find repeating
        const countStr = tools.useChild('stack', '').useChild('val', '').current;
        const count = parseInt(countStr) || 1;
        let cmd = '';

        // whether source === proximity (not moved)
        const coord = this.zoneCtx.zonePath + this.code.current;
        const drop = `${seat}/${hitZone}${stringifyCoordCode(hit.face)}`;

        if (coord === drop) {
            const mode = tools.useChild('tap', '' as string).current;
            const variant = tools.useChild('tap', '').useChild(mode, '' as string).current;
            if (mode === 'rotate') {
                cmd = `spin ${coord} ${variant || 'right'} ${count}`;
            } else if (mode === 'flip') {
                cmd = `flip ${coord} ${variant || 'up'} ${count}`;
            } else if (mode === 'roll') {
                if (variant === 'toss')
                    cmd = `flip ${coord} as d129600`;
                else if (variant === 'spin')
                    cmd = `spin ${coord} as d129600`;
                else
                    cmd = `shuffle ${coord}`;
            } else if (mode === 'view') {
                return {
                    type: 'details' as 'details',
                }
            } else {
                console.log(`unhandled cmd=${mode} variant=${variant}`);
                return null;
            }
        } else {
            // find whether hit face exists
            const hasFace = !attach && (doc.content.zones[`${seat}/${hitZone}`]?.coords || []).find(c => {
                if (c.coord.length !== hit.face.length) return false;
                for(let i = 0; i < c.coord.length; i ++)if(c.coord[i][0] !== hit.face[i][0])return false;
                return true;
            });

            // drop to edge if face exists, drop to face otherwise
            const to = `${seat}/${hitZone}${stringifyCoordCode(hasFace ? hit.edge : hit.face)}`;
            const into = hasFace ? hit.into : 'top';
            cmd = `move ${coord} to ${to} into ${into} count ${count} ${skip ? 'skip ' + skip : ''}`;
        }

        return {
            type: 'cmd' as 'cmd',
            cmd
        };
    }
}

export class PartCtx {
    constructor(public readonly coordCtx: CoordCtx, public i: number) { }

    part = new Selector(coord => coord?.parts[this.i], this.coordCtx.coord);
    exists = new Selector(part => !!part, this.part);
    type = new Selector(part => part?.type, this.part);
    id = new Selector(part => part?.id, this.part);

    usePartState(key: string) {
        const zone = this.coordCtx.zoneCtx.zone;
        const coord = this.coordCtx.coord;
        const part = this.part;
        return useSelector((zone, coord, part, key) => {
            const zp = zone?.zonePartState;
            const cp = coord?.coordPartState;
            const pp = part?.state;
            return pp && pp[key] || cp && cp[key] || zp && zp[key];
        }, zone, coord, part, key);
    }
}

export const docCtx = React.createContext<DocCtx>(null!);
export const seatCtx = React.createContext<SeatCtx>(null!);
export const zoneCtx = React.createContext<ZoneCtx>(null!);
export const coordCtx = React.createContext<CoordCtx>(null!);
export const partCtx = React.createContext<PartCtx>(null!);