import React from "react";
import { RefState } from "./refstate";

export type RefValue<T> = T extends { current: infer V } ? V : T;
export type RefsValue<T extends unknown[]> = T extends [infer X, ...infer Y] ? [RefValue<X>, ...RefsValue<Y>] : [];

function getRefsValue<T extends unknown[]>(sources: T): RefsValue<T> {
    return sources.map(s => {
        if (s instanceof RefState) return s.current;

        const current = s && (s as any).current;
        if (current !== undefined) return current;

        return s;
    }) as RefsValue<T>;
}

export function useSelector<S extends unknown[], T>(
    select: (...vals: RefsValue<S>) => T,
    ...sources: S
) {
    const [selector] = React.useState(() => new Selector(select, ...sources));
    selector.useSelectByRef(sources);
    return selector;
}

export function useSelectorState<S extends unknown[], T>(
    select: (...vals: RefsValue<S>) => T,
    ...sources: S
) {
    const [selector] = React.useState(() => new Selector(select, ...sources));
    selector.useSelectByState(sources);
    return selector;
}

export class Selector<S extends unknown[], T> extends RefState<T> {
    public readonly params: RefState<RefsValue<S>>;
    public readonly sources: RefState<S>;

    constructor(
        public readonly select: (...vals: RefsValue<S>) => T,
        ...sources: S
    ) {
        const params = new RefState(getRefsValue(sources));

        super(select(...params.current));
        this.params = params;
        this.sources = new RefState(sources);
    }

    useSelectByState(sources = this.sources.current){
        const self = this;
        React.useLayoutEffect(() => {
            self.sourceOn();
            self.sources.current = sources;
            return function () {
                self.sourceOff();
            }
        }, [self, ...sources]);

        const params = this.params.useState();
        React.useLayoutEffect(() => {
            self.current = self.select(...params);
        }, [self, ...params]);
    }

    /**
     * ref reacts on layoutEffect.
     */
    useSelectByRef(sources = this.sources.current) {
        React.useLayoutEffect(() => {
            const self = this;
            self.sourceOn();
            self.paramsOn();
            self.sources.current = sources;
            return function () {
                self.paramsOff();
                self.sourceOff();
            }
        }, [this, ...sources]);
    }

    private onSourcesChanged = () => {
        this.params.current = getRefsValue(this.sources.current);
    }

    private sourceOn() {
        const { sources, onSourcesChanged } = this;
        for (const src of sources.current) {
            if(src instanceof RefState)
                src.on(onSourcesChanged);
        }
        sources.on(onSourcesChanged);
        return this;
    }

    private sourceOff() {
        const { sources, onSourcesChanged } = this;
        for (const src of sources.current) {
            if(src instanceof RefState)
                src.off(onSourcesChanged);
        }
        sources.off(onSourcesChanged);
        return this;
    }

    private onParamsChanged = () => {
        this.current = this.select(...this.params.current);
    }

    private paramsOn(){
        this.params.on(this.onParamsChanged);
    }

    private paramsOff(){
        this.params.on(this.onParamsChanged);
    }
}