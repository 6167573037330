import { ContactShadows, Environment, MapControls, Sky, Text } from "@react-three/drei";
import { Canvas, GroupProps, MeshProps, PrimitiveProps, useLoader } from "@react-three/fiber";
import React from "react";
import { Material } from "three";
import { GLTFLoader, MapControls as MapControlsClass } from 'three-stdlib';
import { pointerDispatchCtx, useMemState, useRefState } from "../utils";
import { PartAssetNodes, usePartGeometry, usePartMaterial } from "./partasset";
import { R3FPointerState } from "./r3fpointer";

export function TableRig(props: { children?: any }) {
    const controls = useThreeControls();
    return <Canvas dpr={[1, window.devicePixelRatio]} camera={{ near: 1, far: 1000, position: [0, 40, 10] }} frameloop="demand">
        {props.children}
        <MapControls ref={controls.setState} enableDamping={false} />
        <ContactShadows width={32} height={32} resolution={2048} far={3} opacity={0.6} blur={.1} />
        <Sky distance={450000} turbidity={1.2} rayleigh={0} mieCoefficient={0.014} mieDirectionalG={0.5} sunPosition={[0, 1, 0]} />
        <React.Suspense>
            <Environment files="./env.hdr" />
        </React.Suspense>
    </Canvas>
}

export function PartMesh(props: MeshProps & {
    edge?: Material,
    part: keyof PartAssetNodes,
    uvOffset?: [number, number],
    uvScale?: [number, number],
    print?: string,
    matcap?: string,
    color?: string,
    normal?: string,
}) {
    const {
        edge: edgeMat,
        part, uvOffset, uvScale,
        print, matcap, color, normal,
        ...meshProps
    } = props;
    const geo = usePartGeometry(part, 0, uvOffset, uvScale);
    const edge = usePartGeometry(part, 0.2);
    const mat = usePartMaterial(print, matcap, color, normal);
    return <mesh {...meshProps} geometry={edgeMat ? edge : geo} material={edgeMat || mat} />
}

export function PartLabel(props: { children?: string }) {
    if (!props.children) return null;
    return <Text fontSize={1} color="white" outlineColor="black" outlineWidth={0.05} outlineBlur={0.3}>{props.children}</Text>
}

export function DraggableGroup(props: { enabled?: boolean, lift?: number, pointer?: R3FPointerState } & GroupProps){
    let { enabled, lift, pointer, ...groupProps } = props;

    lift = lift || 1;
    pointer = pointer || useRefState(0 as number, R3FPointerState);

    const pointerctx = React.useContext(pointerDispatchCtx);
    pointer.useR3F();
    let [x, y, z] = pointer.castMovement.useState() || [0, 0, 0];

    if (pointer.useState()) y += (lift || 1) / 3;
    if (pointer.down.current) y += lift || 1;

    React.useLayoutEffect(pointer.down.updateEffect(
        () => {
            const controls = useThreeControls().current;
            if (!controls) return;
            controls.enabled = pointerctx.active.size == 0;
        }
    ), [pointerctx]);

    return <group position={enabled !== false ? [x, y, z] : [0, 0, 0]} {...pointer.bind()}  {...groupProps} />
}

export function useThreeControls(){
    return useMemState<MapControlsClass | null>('three_controls', null);
}

export type GLTFProps = { src?: string } & Omit<PrimitiveProps, 'object'>;
export function GLTFScene(props: GLTFProps) {
    const { src, ...rest } = props;
    return src ? <React.Suspense fallback={null}>
        <GLTFSceneContent src={src} {...rest} />
    </React.Suspense> : null;
}

function GLTFSceneContent(props: GLTFProps) {
    const { src, ...rest } = props;
    const gltf = useLoader(GLTFLoader, src!);
    return <primitive object={gltf.scene} {...rest} />
}