import { GroupProps, MeshProps, useLoader } from "@react-three/fiber";
import React from "react";
import { suspend } from 'suspend-react';
import { MeshMatcapMaterial, RepeatWrapping, sRGBEncoding, TextureLoader } from "three";
// import { coordCtx } from "../../boardcode";
import { applyUVOffset, CardEdgeGeometry, CardGeometry, matcapCeramic, matcapWhite, normalPaper } from "../../r3f";
import { useSelector } from "../../utils";
import { CoordDndWrapper, DetailsWrapper } from "../coorddnd";
import { useCurrentSeatRotation } from "../seatbasis";
import { createPartRenderer } from "./type";

const defaultProps = {
    w: 5.4,
    h: 8.6,
    r: .3,

    u: 0,
    v: 0,
    uvw: 1,
    uvh: 1,
    bu: 0,
    bv: 0,

    front: '',
    back: '',

    th: 0.03,
};
type CardProps = typeof defaultProps;

export const cardRenderer = createPartRenderer(
    [[0, 0, 0], [0, 0, 180]],
    defaultProps,
    ({ parts, template, face, side }) => {

        const [topid, topskip] = useSelector(parts => [parts[0] && parts[0][0].id, parts[0] && parts[0][1]], parts).useState() as [string?, number?];
        const top = template.withId(topid);

        const secid = useSelector(parts => parts[1] && parts[1][0].id, parts).useState();
        const sec = template.withId(secid);

        const len = useSelector(parts => parts.length, parts).useState();
        const th = top?.th || 0.03;

        // const coord = React.useContext(coordCtx);
        // console.log(`${coord.zoneCtx.zonePath}${coord.code.current} topid=${topid} secid=${secid} len=${len} topface=${top?.front}`);

        let [rx, ry, rz] = face.useState();
        ry += side.useState() + useCurrentSeatRotation().useState();

        rx *= Math.PI/180;
        ry *= Math.PI/180;
        rz *= Math.PI/180;

        if (!len) return null;

        const facedir = Math.round(rz / Math.PI);
        const detailface = facedir - Math.floor(facedir / 2) * 2;

        const skewlift = (top!.h * tilt_lift + top!.w * tilt_lift) / 2;
        const basey = th * len + skewlift;

        return <>
            {top && <CoordDndWrapper skip={topskip}>
                <React.Suspense>
                    <Tilt position-y={basey}>
                        <CardFront rotation={[rx, ry, rz]} card={top} />
                        <CardBack rotation={[rx, ry, rz + Math.PI]} card={top} />
                    </Tilt>
                    <DetailsWrapper>
                        <CardDetails card={top} face={detailface} />
                    </DetailsWrapper>
                </React.Suspense>
            </CoordDndWrapper>}
            {sec && <React.Suspense>
                <Tilt position-y={basey - th}>
                    <CardFront rotation={[rx, ry, rz]} card={sec} />
                    <CardBack rotation={[rx, ry, rz + Math.PI]} card={sec} />
                </Tilt>
            </React.Suspense>}
            {sec && <React.Suspense>
                <CardEdge scale={[1, th * len, 1]} card={sec} />
            </React.Suspense>}
        </>
    }
)

const tilt_rad = Math.PI / 180;
const tilt_lift = Math.sin(tilt_rad);
function Tilt(props: GroupProps) {
    return <group {...props} rotation-x={-tilt_rad} rotation-z={tilt_rad}>
        {props.children}
    </group>
}

function CardFront(props: { card: CardProps } & MeshProps) {
    const { card, ...rest } = props;
    const geo = useCardGeometry(card.w, card.h, card.r, card.u, card.v, card.uvw, card.uvh);
    const mat = useCardMat(card.front);
    return <mesh geometry={geo} material={mat} {...rest} />
}

function CardBack(props: { card: CardProps } & MeshProps) {
    const { card, ...rest } = props;
    if (!card.back) {
        const geo = useCardGeometry(card.w, card.h, card.r, card.bu, card.bv, card.uvw, card.uvh);
        const mat = useCardMat(card.front);
        return <mesh geometry={geo} material={mat} {...rest} />
    }
    const geo = useCardGeometry(card.w, card.h, card.r);
    const mat = useCardMat(card.back);
    return <mesh geometry={geo} material={mat} {...rest} />
}

function CardEdge(props: { card: CardProps } & MeshProps) {
    const { card, ...rest } = props;
    const geo = useCardEdgeGeometry(card.w, card.h, card.r);
    const mat = useCardEdgeMat();
    return <mesh geometry={geo} material={mat} {...rest} />
}

function useCardGeometry(w: number, h: number, r: number, u = 0, v = 0, uvw = 1, uvh = 1) {
    return suspend(async (w, h, r, u, v, uvw, uvh) => {
        const geo = new CardGeometry(w, h, r, 5).rotateX(-Math.PI / 2);
        applyUVOffset(geo, u, v, uvw, uvh);
        return geo;
    }, [w, h, r, u, v, uvw, uvh, 'cardgeometry']);
}

function useCardEdgeGeometry(w: number, h: number, r: number) {
    return suspend(async (w, h, r) => {
        return new CardEdgeGeometry(w, h, r, 1, 5).rotateX(-Math.PI / 2);
    }, [w, h, r, 'cardedge']);
}

function useCardMat(mapImage: string) {
    const [print, matcap] = useLoader(TextureLoader, [mapImage, matcapWhite]);

    return suspend(async mapImage => {
        print.encoding = sRGBEncoding;
        print.anisotropy = 4;
        print.flipY = !print.flipY; // why?
        return new MeshMatcapMaterial({
            map: print,
            matcap
        });
    }, [mapImage, 'cardmat']);
}

function useCardEdgeMat() {
    const [normal, matcap] = useLoader(TextureLoader, [normalPaper, matcapCeramic]);

    return suspend(async () => {
        normal.repeat.set(0.01, 2);
        normal.wrapS = normal.wrapT = RepeatWrapping;
        return new MeshMatcapMaterial({
            normalMap: normal,
            matcap
        });
    }, [normalPaper, 'cardedgemat']);
}

function CardDetails(props: { card: CardProps, face: number }) {
    const { card, face } = props;

    const cw = window.innerWidth * .9;
    const ch = window.innerHeight * .9;

    const w = Math.min(cw, ch / card.h * card.w);
    const h = Math.min(ch, cw / card.w * card.h);

    const img = face ? card.back : card.front;
    const sw = face ? 1 : card.uvw;
    const sh = face ? 1 : card.uvh;

    return <div style={{ overflow: 'hidden', width: w, height: h }}>
        <img
            src={img} crossOrigin="anonymous"
            width={w * sw} height={h * sh}
            style={{ transform: `translate(-${w * card.u}px, -${h * card.v}px)` }}
        />
    </div>
}