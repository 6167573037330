import { Boardcode } from "@ttws/boardcode";
import React from "react";
import { Object3D, Vector3 } from "three";
import { OrbitControls } from "three-stdlib";
import { seatCtx, zoneCtx } from "../boardcode";
import { useMemState, useSelector } from "../utils";

export function lookAtBasis(seat: string, controls: OrbitControls | null) {
    if (!controls) return;

    const basis = useSeatBasis(seat).current || origin;
    const [cx, cy, cz] = local2world(basis, .5, 0, .5);

    const { x: sx, y: sy, z: sz } = basis.scale;
    const dist = Math.max(sx, sy, sz);

    v1.set(0, dist, dist / 4).applyEuler(basis.rotation);
    const { x: px, y: py, z: pz } = v1;

    controls.target.set(cx, cy, cz);
    controls.object.position.set(cx + px, cy + py, cz + pz);
    controls.object.lookAt(controls.target);
    controls.update();
}

export function useSeatBasis(seat: string) {
    return useMemState<Object3D | null>('seatbasis_' + seat, null);
}

export function findSeatHit(seats: string[], drag: Boardcode.Vec3 | null) {
    if (!drag) return null;
    for (const seat of seats) {
        const [xpos, _, zpos] = findPositionInSeat(seat, ...drag);
        if (xpos >= 1 || xpos < 0 || zpos >= 1 || zpos <= 0) continue;
        return [ seat, xpos, zpos ] as [string, number, number];
    }
}
function findPositionInSeat(seat: string, ...pos: Boardcode.Vec3){
    const basis = useSeatBasis(seat).current || origin;
    return world2local(basis, ...pos);
}

const origin = new Object3D();
export function useCurrentSeatArea(...area: Boardcode.Vec4) {
    const seat = React.useContext(seatCtx).seatKey;
    const basis = useSeatBasis(seat);
    return useSelector((basis, ...area) => {
        return area2transform(basis || origin, ...area);
    }, basis, ...area);
}
export function useCurrentSeatPosition(...pos: Boardcode.Vec3) {
    const seat = React.useContext(seatCtx).seatKey;
    const basis = useSeatBasis(seat);
    return useSelector((basis, ...pos) => {
        return local2world(basis || origin, ...pos);
    }, basis, ...pos);
}
export function useCurrentSeatRotation() {
    const seat = React.useContext(seatCtx).seatKey;
    const basis = useSeatBasis(seat);
    return useSelector(basis => {
        return (basis || origin).rotation.y * 180 / Math.PI;
    }, basis);
}

export function useCurrentZoneOutline(gap = .2) {
    const seat = React.useContext(seatCtx).seatKey;
    const basis = useSeatBasis(seat);
    const zone = React.useContext(zoneCtx).layoutSize;
    return useSelector((basis, area, gap) => {
        if (!basis) return [];
        return area2line(basis, ...area, gap);
    }, basis, zone, gap);
}

const v1 = new Vector3();
function local2world(basis: Object3D, x: number, y: number, z: number) {
    v1.set(x, y, z);
    basis.localToWorld(v1);
    return [v1.x, v1.y, v1.z] as [number, number, number];
}
function world2local(basis: Object3D, x: number, y: number, z: number) {
    v1.set(x, y, z);
    basis.worldToLocal(v1);
    return [v1.x, v1.y, v1.z] as [number, number, number];
}

function area2transform(basis: Object3D, minx: number, minz: number, maxx: number, maxz: number) {
    const [px, py, pz] = local2world(basis, minx * .5 + maxx * .5, 0, minz * .5 + maxz * .5);
    const sx = maxx - minx;
    const sy = 1;
    const sz = maxz - minz;
    const { x: bx, y: by, z: bz } = basis.scale;
    const { x: rx, y: ry, z: rz } = basis.rotation;

    return {
        position: [px, py, pz] as [number, number, number],
        scale: [sx * bx, sy * by, sz * bz] as [number, number, number],
        rotation: [rx, ry, rz] as [number, number, number],
    };
}

function area2line(basis: Object3D, minx: number, minz: number, maxx: number, maxz: number, gap = 0) {
    const gx = gap / basis.scale.x;
    const gz = gap / basis.scale.z;
    const p1 = local2world(basis, minx+gx, 0, minz+gz);
    const p2 = local2world(basis, minx+gx, 0, maxz-gz);
    const p3 = local2world(basis, maxx-gx, 0, maxz-gz);
    const p4 = local2world(basis, maxx-gx, 0, minz+gz);
    return [p1, p2, p3, p4, p1];
}