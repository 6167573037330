import React from "react";
import styled from "styled-components";
import { Modal, RefState } from "../utils";

export function Dialog(props: { open: RefState<boolean>, children?: React.ReactNode }) {
    const { open, children } = props;
    const close = React.useCallback(() => open.current = false, [open]);

    const isOpen = open.useState();
    if (!isOpen) return null;

    return <Modal>
        <Blackdrop onClick={close}>
            {children}
        </Blackdrop>
    </Modal>
}

const Blackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background: rgba(0,0,0,.25);
    display: flex;
    justify-content: center;
    align-items: center;
`