import React from 'react';
import { RefState, useRefState } from '../../utils';
import mqtt from 'mqtt';

export class MqttClientCtx {
    readonly clientId = Math.random().toString(16).slice(-8);
    readonly client = new RefState(null as null | mqtt.Client);
    readonly connected = new RefState(false);
    readonly head = new RefState({ topic: '', payload: '' });
    readonly scope: string;
    constructor(scope: string, public readonly server = 'wss://broker-cn.emqx.io:8084/mqtt') {
        this.scope = scope + '/';
    }

    send(topic: string, msg: string){
        this.client.current?.publish(this.scope + topic, msg);
    }

    useConnection(){
        const { client, clientId, connected, scope, server, head } = this;
        React.useLayoutEffect(() => {
            const c = client.current = mqtt.connect(server, {
                clientId,
                will: {
                    topic: scope + 'clients',
                    payload: `${clientId} LEAVES`,
                    qos: 2,
                    retain: false
                }
            });
            c.on('connect', () => {
                connected.current = true;
            });
            c.on('disconnect', () => {
                connected.current = false;
            });
            c.on('reconnect', () => {
                connected.current = true;
            });
            const dec = new TextDecoder();
            c.on('message', (topic: string, payload: string | Uint8Array, qos) => {
                if (payload instanceof Uint8Array)
                    head.current = { topic, payload: dec.decode(payload) };
                else
                    head.current = { topic, payload };
            });

            return function(){
                connected.current = false;
                c.end();
                client.current = null;
            }
        }, [client, clientId, connected, scope, server, head]);
    }

    useTopic(topic: string){
        const msg = useRefState({ topic, payload: '' });

        const { scope, head } = this;
        const client = this.client.useState();
        const connected = this.connected.useState();

        const readyClient = connected && client;
        const path = scope + topic;

        React.useLayoutEffect(() => {
            if (!readyClient) return;

            const onData = (data: {topic: string, payload: string}) => {
                if(data.topic === path){
                    msg.current = data;
                }
            }

            head.on(onData);
            readyClient.subscribe(path);
            return function(){
                readyClient.unsubscribe(path);
                head.off(onData);
            }
        }, [readyClient, path, head, msg]);

        return msg;
    }
}