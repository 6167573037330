import { Html } from "@react-three/drei";
import { Boardcode } from "@ttws/boardcode";
import React from "react";
import styled from "styled-components";
import { coordCtx } from "../../boardcode";
import { RefState, useRefState, useSelector } from "../../utils";
import { Dialog } from "../dialog";
import { useCurrentSeatRotation } from "../seatbasis";
import { createPartRenderer } from "./type";

export const trackerRenderer = createPartRenderer(
    [],
    {
        name: 'score',
        init: 0,
        max: 100,
        min: 0,
    },
    ({ parts, template, }) => {
        const topid = useSelector(parts => parts[0] && parts[0][0].id, parts).useState();
        if (!topid) return null;

        const { name, init, max, min } = template.withId(topid)!;
        return <Tracker name={name} init={init} max={max} min={min} parts={parts} />
    }
)

function Tracker(props: { name: string, init: number, max: number, min: number, parts: RefState<[Boardcode.Part, number][]> }) {
    const { name, init, max, min, parts } = props;

    const valref = useSelector((parts, name, init) => {
        const state = parts[0][0].state;
        const val = parseInt(state && state[name] || '');
        if (isNaN(val)) return init;
        return val;
    }, parts, name, init);

    const skip = useSelector(parts => parts[0][1], parts);

    const coord = React.useContext(coordCtx);
    const plus = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();

        const doc = coord.zoneCtx.seatCtx.docCtx;
        const to = Math.min(max, valref.current + 1);
        if (to === valref.current) return;

        const cmd = `mark ${name} in ${coord.zoneCtx.zonePath}${coord.code.current} to ${to} skip ${skip.current}`;
        doc.applyCommand(cmd);
    }, [valref, skip, name, max, coord]);

    const minus = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();

        const doc = coord.zoneCtx.seatCtx.docCtx;
        const to = Math.max(min, valref.current - 1);
        if (to === valref.current) return;

        const cmd = `mark ${name} in ${coord.zoneCtx.zonePath}${coord.code.current} to ${to} skip ${skip.current}`;
        doc.applyCommand(cmd);
    }, [valref, skip, name, min, coord]);

    const val = valref.useState();
    return <group rotation-y={useCurrentSeatRotation().useState() * Math.PI / 180}>
        <Html rotation-x={-Math.PI / 2} position-z={1} center transform zIndexRange={[1,0]}>
            <DetailsTracker>
                <TrackerLabel className="big">
                    <DialogOnly onClick={minus}>-</DialogOnly>
                    {val}
                    <DialogOnly onClick={plus}>+</DialogOnly>
                </TrackerLabel>
                <TrackerLabel>{name}</TrackerLabel>
            </DetailsTracker>
        </Html>
    </group>
}

function DetailsTracker(props: { children?: any }) {
    const dialog = useRefState(false);
    const onClick = React.useCallback(() => dialog.current = true, [dialog]);
    return <>
        <TrackerSurface onClick={onClick}>
            {props.children}
        </TrackerSurface>
        <Dialog open={dialog}>
            <TrackerDialog>
                {props.children}
            </TrackerDialog>
        </Dialog>
    </>
}

const TrackerLabel = styled.div`
    color: white;
    font-size: 12mm;
    font-weight: bold;
    filter: drop-shadow(0 0 2mm black);

    &.big{
        font-size: 24mm;
    }
`

const TrackerSurface = styled.div`
    border-radius: 3mm;
    padding: .5em 4em;
    text-align: center;

    transition: .3s background;
    background: rgba(0,0,0,.1);

    &:hover{
        background: rgba(0,0,0,.5);
    }

    user-select: none;
    cursor: pointer;
`;

const TrackerDialog = styled.div`
    display: flex;
    flex-direction: column;

    border-radius: 3mm;
    padding: .5em 4em;
    text-align: center;

    background: black;
`;

const DialogOnly = styled.span`
    ${TrackerSurface} & {
        display: none;
    }
    user-select: none;
`;