import React from "react";
import { RefState } from "./refstate";

export class PointerContext {
    active = new Map<number, RefState<PointerEvent>>();

    updatePointer = (e: PointerEvent) => {
        const ref = this.active.get(e.pointerId);
        if (!ref) return;
        ref.current = e;
    }

    addPointer = (e: PointerEvent) => {
        const ref = this.active.get(e.pointerId);
        if (ref) return ref;

        const newref = new RefState(e);
        this.active.set(e.pointerId, newref);
        return newref;
    }

    removePointer = (e: PointerEvent) => {
        const ref = this.active.get(e.pointerId);
        if (!ref) return;
        this.active.delete(e.pointerId);
        ref.current = e;
        return ref;
    }

    clear = () => {
        const actives = [...this.active.values()];
        this.active.clear();
        for(const each of actives){
            each.setState(new PointerEvent('pointerup'));
        }
    }

    constructor(){
        window.addEventListener('pointermove', this.updatePointer);
        window.addEventListener('pointerup', this.removePointer);
        window.addEventListener('pointercancel', this.removePointer);
        window.addEventListener('blur', this.clear );
    }

    dispose(){
        window.removeEventListener('pointermove', this.updatePointer);
        window.removeEventListener('pointerup', this.removePointer);
        window.removeEventListener('pointercancel', this.removePointer);
        window.removeEventListener('blur', this.clear );
    }
}
export const pointerDispatchCtx = React.createContext(new PointerContext);

export class PointerState extends RefState<number> {

    hover = new RefState(false);
    down = new RefState(null as null | PointerEvent);
    drag = new RefState(null as null | PointerEvent);

    useDebug(ctx?: PointerContext){
        ctx = ctx || React.useContext(pointerDispatchCtx);
        React.useLayoutEffect(this.updateEffect(
            state => {
                console.log(`id: ${state}`);
            }
        ), [this]);
        React.useLayoutEffect(this.hover.updateEffect(
            state => {
                console.log(`hover: ${state}`);
            }
        ), [this]);
        React.useLayoutEffect(this.down.updateEffect(
            state => {
                console.log(`down: ${state}; all: ${[...ctx!.active.keys()].join(', ')}`);
            }
        ), [this]);
        React.useLayoutEffect(this.drag.updateEffect(
            state => {
                console.log(`drag: ${state}`);
            }
        ), [this]);
    }

    onPointerEnter = (e: PointerEvent) => {
        if (this.current) return;

        this.current = e.pointerId;
        this.hover.current = true;
    }

    onPointerLeave = (e: PointerEvent) => {
        this.hover.current = false;

        if (this.down.current) return;

        this.current = 0;
    }

    onPointerDown = (e: PointerEvent, ctx: PointerContext) => {
        const ref = ctx.addPointer(e);
        const onPointer = (e: PointerEvent) => {
            if(e.type === 'pointermove'){
                this.drag.current = e;
            }else{
                this.drag.current = null;
                this.down.current = null;
                if (!this.hover.current) this.current = 0;
                ref.off(onPointer);
            }
        }
        ref.on(onPointer);
        this.down.current = e;
        this.drag.current = e;
    }
}