import React from "react";
import { tools } from "../boardcode";
import { Tool, ToolDivider, ToolLayer, toolCtx } from "./toolitem";

export function BoardcodeToolbar() {
    return <>
        <ToolLayer><toolCtx.Provider value={tools.useChild("tap", '' as string)}>
            <Tool item={{ name: "grab", icon: "icon-hand", hotkey: "g" }} />
            <Tool item={{ name: "view", icon: "icon-eye", hotkey: "v" }} />

            <Tool item={{
                name: "rotate", children: [
                    { name: 'right', icon: 'icon-rotate-right', hotkey: 'e' },
                    { name: 'left', icon: 'icon-rotate-left', hotkey: 'shift+e' },
                ]
            }} />

            <Tool item={{
                name: "flip", children: [
                    { name: 'up', icon: 'icon-flip', sublabel: <i className="iconfont icon-add" />, hotkey: 'f' },
                    { name: 'down', icon: 'icon-flip', sublabel: <i className="iconfont icon-minus" />, hotkey: 'shift+f' },
                ]
            }} />

            <Tool item={{
                name: "roll", children: [
                    { name: 'toss', icon: "icon-dice", hotkey: "r" },
                    { name: 'spin', icon: "icon-spin", hotkey: "shift+r" },
                    { name: 'shuffle', icon: "icon-shuffle", hotkey: "s" },
                ]
            }} />
        </toolCtx.Provider></ToolLayer>
        <ToolDivider />
        <ToolLayer><toolCtx.Provider value={tools.useChild("stack", '' as string)}>
            <Tool item={{
                name: "val", children: [
                    { name: '1', icon: 'icon-stack', hotkey: '1' },
                    { name: '3', icon: 'icon-stack', hotkey: '3', sublabel: '3' },
                    { name: '5', icon: 'icon-stack', hotkey: '5', sublabel: '5' },
                    { name: '7', icon: 'icon-stack', hotkey: '7', sublabel: '7' },
                ]
            }} />
            <Tool item={{
                name: 'border', 
                children: [
                    { name: 'none', icon: 'icon-border-none', },
                    { name: 'solid', icon: 'icon-border-solid', },
                    { name: 'text', icon: 'icon-text', },
                ]
            }}/>
        </toolCtx.Provider></ToolLayer>
    </>
}