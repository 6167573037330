import { Boardcode, selectCyclicInt } from "@ttws/boardcode";
import React from "react";
import { PatternObject, RefState, useSelector } from "../../utils";
import { PartRendererMap, PartTemplate } from "./type";

export interface PartListProps<R extends PartRendererMap> {
    renderers: R,
    templates: {
        [key: string]: PartTemplate<R>
    },
    parts: RefState<Boardcode.Part[]>,
    face: RefState<string | undefined>,
    side: RefState<string | undefined>,
}

export function PartList<R extends PartRendererMap>(props: PartListProps<R>) {
    const { renderers, templates, face, side, parts } = props;

    return <>{Object.keys(templates).map(
        type => <PartRender
            key={type} type={type}
            renderer={renderers[templates[type].renderer]} template={templates[type]}
            face={face} side={side} parts={parts} />
    )}</>
}

function PartRender<R extends PartRendererMap>(props: {
    renderer: R[keyof R],
    type: string,
    template: PartTemplate<R>,
    parts: RefState<Boardcode.Part[]>,
    face: RefState<string | undefined>,
    side: RefState<string | undefined>,
}) {
    const { renderer, type, template, parts, face: srcface, side: srcside } = props;

    const rendered = useSelector((type, parts) => {
        const rendered = [] as [Boardcode.Part, number][];
        for(let i = 0; i < parts.length; i ++){
            const part = parts[i];
            if(part.type === type){
                rendered.push([part, i]);
            }
        }
        return rendered;
    }, type, parts);

    const templateObj = React.useMemo(() => new PatternObject(template, template.pattern, '', renderer.defaults), [template, renderer]);

    const face = useSelector((face, faces) => faces[selectCyclicInt(face, faces.length)] || [0, 0, 0], srcface, renderer.faces);
    const side = useSelector((side, sides) => sides[selectCyclicInt(side, sides.length)] || 0, srcside, template.sides || []);

    const exists = useSelector(parts => parts.length > 0, rendered).useState();
    if (!exists) return null;

    return <React.Suspense fallback={null}>
        <renderer.Component parts={rendered} template={templateObj} face={face} side={side} />
    </React.Suspense>
}