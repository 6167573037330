import { parseDocCode, stringifyDocCode } from "@ttws/boardcode";
import produce from "immer";
import React from "react";
import { CommandHandler, docCtx } from "../../boardcode";
import { useRefState } from "../../utils";
import { MqttClientCtx } from "./mqtt";

export function MqttCommandHandler(props: { scope: string, server?: string }) {
    const doc = React.useContext(docCtx);
    const { scope, server } = props;

    // connect to mqtt
    const mqtt = React.useMemo(() => new MqttClientCtx(scope, server), [scope, server]);
    mqtt.useConnection();

    // hijack command handling
    const commandHandler = React.useCallback<CommandHandler>(cmd => {
        mqtt.send('boardcode/cmd', cmd);
    }, [mqtt]);
    React.useLayoutEffect(mqtt.connected.updateEffect(on => {
        doc.commandHandler = on ? commandHandler : undefined;
    }), [mqtt, doc, commandHandler]);

    // protocol -- spaghetti ahead

    // cmd -> run it
    const cmds = mqtt.useTopic('boardcode/cmd');
    React.useLayoutEffect(cmds.updateEffect(cmd => {
        console.log("loading cmd");
        doc.run(cmd.payload);
    }), [doc, cmds]);

    // doc -> load it
    const docs = mqtt.useTopic('boardcode/doc');
    React.useLayoutEffect(docs.updateEffect(adoc => {
        console.log("loading doc");
        doc.doc.current = parseDocCode(adoc.payload);
    }), [doc, docs]);

    // connected -> greet
    const greets = mqtt.useTopic('clients');
    React.useLayoutEffect(mqtt.connected.updateEffect(on => {
        console.log("joined");
        if (on) mqtt.send('clients', `${mqtt.clientId} GREETS`);
    }), [mqtt]);

    const players = useRefState(() => {
        const map = {} as { [key: string]: boolean };
        map[mqtt.clientId] = true;
        return map;
    });

    // greets -> nodsto
    // nodsto -> pleadto
    // pleadto -> doc
    React.useLayoutEffect(greets.updateEffect(greet => {
        const [dude, action, dude2] = greet.payload.split(' ');

        if(action === 'GREETS'){
            mqtt.send('clients', `${mqtt.clientId} NODSTO ${greet.payload.slice(0, 8)}`);

        }else if(action === 'NODSTO'){
            if (dude2 === mqtt.clientId && Object.keys(players.current).length === 1) {
                mqtt.send('clients', `${mqtt.clientId} PLEADTO ${dude}`);
            }
            players.current = produce(players.current, map => {
                map[dude] = true;
            });

        }else if(action === 'PLEADTO'){
            if(dude2 === mqtt.clientId){
                mqtt.send('boardcode/doc', stringifyDocCode(doc.doc.current));
            }

        }else if(action === 'LEAVES'){
            players.current = produce(players.current, map => {
                delete map[dude];
            });
        }

        console.log(dude, action, dude2, players.current);
    }), [mqtt, players, doc]);

    return null;
}