import { Boardcode } from "@ttws/boardcode";
import React from "react";
import { PatternContent, PatternObject, PatternValue, RefState } from "../../utils";

export type PartComponent<P extends PatternValue> = (props: {
    parts: RefState<[Boardcode.Part, number][]>,
    template: PatternObject<P>,
    face: RefState<Boardcode.Vec3>,
    side: RefState<number>
}) => React.ReactElement | null;

export type PartRenderer<P extends PatternValue> = {
    faces: Boardcode.Vec3[];
    defaults: P;
    Component: PartComponent<P>
}

export type PartRendererMap = {
    [key: string]: PartRenderer<any>;
}

export type PartTemplate<M extends PartRendererMap, K extends keyof M = keyof M> = {
    pattern: string;
    renderer: K;
    sides?: number[];
} & Partial<PatternContent<M[K]['defaults']>>;

export function createPartRenderer<P extends PatternValue>(
    faces: PartRenderer<P>['faces'],
    defaults: PartRenderer<P>['defaults'],
    Component: PartRenderer<P>['Component'],
){
    return {
        faces, defaults, Component
    } as PartRenderer<P>;
}