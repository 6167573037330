import hotkeys from "hotkeys-js";
import React from "react";
import styled from 'styled-components';
import { EnvRefState, JSONValue, useRefState, useSelector, useSessionStorageState } from "../utils";

export const ToolContainer = styled.div`
    position: relative;

    min-width: 1.5em;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;

    border: .1em solid transparent;
    border-width: 0 .1em 0 0;
    box-sizing: content-box;
    @media (max-aspect-ratio: 2/2) {
        border-width: 0 0 .1em 0;
    }

    transition: .3s color, .3s background, .3s border-color;
    color: rgba(0,0,0,.5);
    user-select: none;

    &.disabled {
        color: rgba(255,0,0,.5);
    }

    &.active {
        border-color: darkcyan;
        color: black;
    }

    :hover {
        color: black;
    }

    text-align: center;

    // iconfont from https://www.iconfont.cn/manage/index?manage_type=myprojects&projectId=3395831
    @import url(//at.alicdn.com/t/font_3395831_n2ff5uxn04.css);
    i {
        font-family: iconfont;
        font-style: normal;
    }

    .label {
        position: absolute;

        right: .1em;
        top: .1em;

        line-height: 1.2em;
        font-size: .7em;

        font-family: sans-serif;
    }
`

export const ToolLayer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;

    @media (max-aspect-ratio: 2/2) {
        flex-direction: row;
    }

    background-color: white;
    box-shadow: 0 0 1em rgba(0,0,0,.25);
    border-radius: .2em;
`;

export const ToolSubLayer = styled.div`
    height: 0;
    transform: translateX(2em);
    ${ToolLayer}{
        transform: translateY(-0.75em) translateY(-50%);
    }

    @media (max-aspect-ratio: 2/2) {
        transform: translateY(.5em);
        ${ToolLayer}{
            transform: translateX(.75em) translateX(-50%);
        }
    }

    display: none;
    &.open{
        display: block;
    }
`;

export const ToolDivider = styled.div`
    width: 1em;
    height: 1em;
`;


export const ToolHud = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 1.5em;
    height: 100vh;

    font-size: 6mm;

    ${ToolLayer} {
        border-radius: 0 .2em. 2em 0;
    }

    @media (max-aspect-ratio: 2/2) {
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 1.5em;

        flex-direction: row;

        ${ToolLayer} {
            border-radius: 0 0 .2em. 2em;
        }
    }
`;

export const toolCtx = React.createContext(useSessionStorageState('tools', '' as string));

export interface ToolItem{
    icon?: string,
    sublabel?: React.ReactNode,

    name: string, // 
    hotkey?: string,
    onTrigger?: () => void,

    children?: ToolItem[],
}

export function Tool(props: { item: ToolItem }) {
    const { item } = props;
    const { name, onTrigger, children } = item;

    const group = React.useContext(toolCtx);
    const focus = useRefState(false);

    const callbacks = React.useMemo(() => {
        return {
            activate(){
                group.setState(name);
                onTrigger && onTrigger();
            },
            open() {
                group.setState(name);
                focus.setState(true);
                onTrigger && onTrigger();
            },
            dismiss(){
                focus.setState(false);
            }
        }
    }, [group, focus, name, onTrigger]);

    const selfState = group.useChild(name, '' as string);
    children && selfState.useState();
    const hotkey = collectHotkey(item);
    const { icon, sublabel } = findActiveItem(item, selfState);

    const active = useSelector((group, option) => group === option, group, name).useState();
    const disabled = selfState.useChild('disabled', false as boolean).useState();
    const open = focus.useState();
    const ref = React.useRef<HTMLDivElement>(null);
    const className = `${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`;

    useDismiss(ref, callbacks.dismiss);
    useHotkey(hotkey, disabled ? undefined : callbacks.activate);
    const onPointerUp = disabled ? undefined : callbacks.open;

    return <ToolContainer ref={ref} className={className} onPointerUp={onPointerUp}>
        <toolCtx.Provider value={selfState}>
            {icon ? <i className={icon} /> : null}
            {sublabel && <span className="label">{sublabel}</span>}
            {children && <ToolSubLayer className={open ? "open" : ''}>
                <ToolLayer>
                    {children.map(child => <Tool item={child} key={child.name} />)}
                </ToolLayer>
            </ToolSubLayer>}
        </toolCtx.Provider>
    </ToolContainer>
}

function collectHotkey(item: ToolItem): string | undefined {
    if (item.hotkey) return item.hotkey;
    if (item.children) return item.children.map(collectHotkey).filter(one => !!one).join(',') || undefined;
}

function findActiveItem(item: ToolItem, active: EnvRefState<string, JSONValue>): ToolItem {
    if (!item.children) return item;

    const child = item.children.find(one => one.name === active.current);
    if (child) return findActiveItem(child, active.useChild(child.name, '' as string));

    return item.children[0];
}

function useDismiss(ref: React.RefObject<HTMLDivElement>, dismiss?: () => void) {
    React.useLayoutEffect(() => {
        if (!dismiss) return;

        const onDismiss = (e: PointerEvent) => {
            const target = e.target as HTMLElement;
            if (target && !ref.current!.contains(target)) {
                dismiss();
            }
        }

        window.addEventListener('pointerdown', onDismiss);
        return function () {
            window.removeEventListener('pointerdown', onDismiss);
        }
    }, [dismiss, ref]);
}

function useHotkey(hotkey?: string, activate?: () => void) {
    React.useLayoutEffect(() => {
        if (!hotkey || !activate) return;

        const trigger = () => { activate() };
        hotkeys(hotkey, trigger);
        return function () {
            hotkeys.unbind(hotkey, trigger);
        }

    }, [hotkey, activate]);
}