import React from "react";

export class ErrorBoundary extends React.Component<{
    children?: React.ReactNode,
    fallback: React.FunctionComponent<{ error: Error, recover: () => void }>
}, { error?: Error }> {

    recover = () => this.setState({ error: undefined });

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    render() {
        if (this.state?.error) {
            const Fallback = this.props.fallback;
            return <Fallback error={this.state.error} recover={this.recover} />
        }

        return this.props.children;
    }
}