import React from "react";
import ReactDOM from 'react-dom/client';
import { RefState, useRefState } from "./refstate";

class ModalRefstate extends RefState<React.ReactNode>{
    dom = document.createElement('div');
    root = ReactDOM.createRoot(this.dom);
    constructor(node: React.ReactNode) {
        super(node);
        this.dom.id = Math.random().toString(16).slice(-8);
        this.dom.style.zIndex = '100';
        this.dom.style.position = 'absolute';
        this.on(children => this.root.render(children));
    }
}

export function Modal(props: { children?: React.ReactNode }) {
    const modal = useRefState<React.ReactNode, ModalRefstate>(undefined, ModalRefstate);
    const { children } = props;

    React.useLayoutEffect(() => {
        modal.current = children;
        document.body.append(modal.dom);

        return function () {
            modal.dom.remove();
        }
    }, [modal, children]);

    return null;
}