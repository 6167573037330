import { BufferGeometryNode, extend } from "@react-three/fiber";
import { BufferGeometry, Float32BufferAttribute, Shape, ShapeBufferGeometry } from "three";

export class CardEdgeGeometry extends BufferGeometry{
    constructor(width: number, height: number, rad: number, thickness: number, segs = 12){
        super();

        const shape = roundedRectShape(width, height, rad);
        const points = shape.getPoints(segs);

        const indices = [] as number[];
        const vertices = [] as number[];
        const uvs = [] as number[];

        const len = points.length;
        for(let i = 0; i < len; i ++){
            const st = points[i];

            vertices.push(st.x, st.y, thickness);
            vertices.push(st.x, st.y, 0);
            uvs.push(st.x + st.y, thickness, st.x + st.y, 0);

            const a = i * 2;
            const b = a + 1;
            const c = ((i + 1) % len) * 2;
            const d = c + 1;
            indices.push(a, b, c, b, d, c);
        }

        this.setIndex( indices );
		this.setAttribute( 'position', new Float32BufferAttribute( vertices, 3 ) );
		this.setAttribute( 'uv', new Float32BufferAttribute( uvs, 2 ) );
        this.computeVertexNormals();
    }
}

export function applyUVOffset(geo: BufferGeometry, u: number, v: number, su = 1, sv = 1){
    const array = geo.attributes.uv.array as Float32Array;
    for(let i = 0; i < array.length; i += 2){
        array[i] = (array[i] + u) / su;
        array[i+1] = (array[i+1] + v) / sv;
    }
}

export function makeEdgeGeometry(geo: BufferGeometry, d: number, scale = 1){
    const clone = geo.clone();
    const { normal, position } = clone.attributes;
    const posarr = position.array as Float32Array;
    const normarr = normal.array as Float32Array;
    let n = posarr.length;
    for(let i = 0; i < n; i ++){
        posarr[i] = posarr[i] * scale + normarr[i] * d;
    }

    const ia = clone.getIndex()!.array as Uint16Array;
    for(let i = 0; i < ia.length; i += 3){
        const t = ia[i];
        ia[i] = ia[i+1];
        ia[i+1] = t;
    }
    return clone;
}

export class CardGeometry extends ShapeBufferGeometry{
    constructor(width: number, height: number, rad: number, segs = 12){
        super(roundedRectShape(width, height, rad), segs);
        const uv = this.attributes.uv as Float32BufferAttribute;
        const arr = uv.array as Float32Array;
        const len = arr.length;
        for (let i = 0; i < len; i += 2){
            arr[i] = arr[i] / width + 0.5;
            arr[i+1] = -arr[i+1] / height + 0.5;
        }
    }
}

export function roundedRectShape(width: number, height: number, rad: number) {
    const hw = width / 2;
    const hh = height / 2;

    const shape = new Shape();
    shape.moveTo(-hw + rad, -hh);

    shape.lineTo(hw - rad, -hh);
    shape.quadraticCurveTo(hw, -hh, hw, -hh + rad);

    shape.lineTo(hw, hh - rad);
    shape.quadraticCurveTo(hw, hh, hw - rad, hh);

    shape.lineTo(-hw + rad, hh);
    shape.quadraticCurveTo(-hw, hh, -hw, hh - rad);

    shape.lineTo(-hw, -hh + rad);
    shape.quadraticCurveTo(-hw, -hh, -hw + rad, -hh);
    return shape;
}

extend({CardGeometry, CardEdgeGeometry});
declare global {
    namespace JSX {
        interface IntrinsicElements{
            cardGeometry: BufferGeometryNode<CardGeometry, typeof CardGeometry>;
            cardEdgeGeometry: BufferGeometryNode<CardEdgeGeometry, typeof CardEdgeGeometry>;
        }
    }
}