import React from "react";
import { coordCtx } from "../boardcode";
import { DraggableGroup, R3FPointerState } from "../r3f";
import { RefState, useRefState } from "../utils";
import { Dialog } from "./dialog";
import { findSeatHit } from "./seatbasis";

export function CoordDndWrapper(props: { enabled?: boolean, skip?: number, attach?: boolean, children?: React.ReactNode }) {
    const coord = React.useContext(coordCtx);
    const pointer = useRefState(0 as number, R3FPointerState);
    const detailsOpen = useRefState(false);
    const { enabled, skip, attach, children } = props;

    React.useLayoutEffect(pointer.castDrag.updateEffect((now, prev) => {
        if (now || !prev) return;

        const offset = pointer.targetOffset;
        const seatHit = findSeatHit(coord.zoneCtx.seatCtx.docCtx.seatKeys.current, [
            prev[0] + offset.x,
            prev[1] + offset.y,
            prev[2] + offset.z,
        ]);
        if (!seatHit) return;

        const action = coord.findPointerAction(seatHit, skip, attach);
        if (!action) return;

        if (action.type === 'cmd') {
            const { cmd } = action;
            coord.zoneCtx.seatCtx.docCtx.applyCommand(cmd);
        } else if (action.type === 'details') {
            detailsOpen.current = true;
        }
    }), [pointer, detailsOpen, coord, skip, attach]);

    return <detailsCtx.Provider value={detailsOpen}>
        <DraggableGroup enabled={enabled} children={children} pointer={pointer}/>
    </detailsCtx.Provider >
}

const detailsCtx = React.createContext(new RefState(false));
export function DetailsWrapper(props: { children?: React.ReactNode }) {
    const open = React.useContext(detailsCtx);
    return <Dialog open={open}>
        {props.children}
    </Dialog>
}