import styled from "styled-components";
import React from "react";

export function Errors(props: { error: Error, recover: () => void }) {
    const { error, recover } = props;

    return <ErrorContainer>
        <h1>遭遇错误</h1>
        <h2>{error.message}</h2>
        <pre>
            {error.stack}
        </pre>
        <a onClick={recover} href='#'>忽略</a>
    </ErrorContainer>
}

const ErrorContainer = styled.div`
    max-width: 600px;
    margin: auto;
    padding: 1em;
`