import { useLoader } from "@react-three/fiber";
import { Boardcode } from "@ttws/boardcode";
import React from 'react';
import { suspend } from 'suspend-react';
import { Mesh, MeshMatcapMaterial, TextureLoader } from "three";
import { glbParts, matcapCeramic, PartAssetNodes, usePartAssets } from "../../r3f";
import { useSelector } from "../../utils";
import { CoordDndWrapper } from "../coorddnd";
import { createPartRenderer } from "./type";

export const pieceRenderer = createPartRenderer(
    [[0, 0, 0]],
    {
        shape: 'cube' as keyof PartAssetNodes,
        color: 'red',
        src: glbParts
    },
    ({ parts, template, face, side }) => {

        const items = useSelector(parts => parts.map(([part, skip]) => {
            const { shape, color, src } = template.withId(part.id) || {};
            return [shape, color, src, skip] as [string, string, string, number];
        }), parts).useState();

        if (!items.length) return null;

        const [max, height] = pilemax(items.length);
        const shift = max - items.length + 1;

        return <>{
            items.map(([shape, color, src, skip], i) => {
                if (!shape) return null;

                const [x, y, z] = pilepos(i + shift);
                return <React.Suspense key={i}>
                    <Piece shape={shape} src={src} color={color} position={[x, height - 1 - y, z]} skip={skip} />
                </React.Suspense>
            })
        }</>
    }
);

function Piece(props: { shape: string, src: string, color?: string, position: Boardcode.Vec3, skip?: number }) {
    const { shape, src, color, position, skip } = props;
    const geo = usePartAssets<{ [key: string]: Mesh }>(src)[shape]?.geometry;
    const mat = usePieceMat(color);
    if (!geo) return null;

    if (!geo.boundingBox) geo.computeBoundingBox();
    const { max, min } = geo.boundingBox!;
    let [x, y, z] = position;
    x *= (max.x - min.x) * 1.08;
    y *= (max.y - min.y) * 1.08;
    z *= (max.z - min.z) * 1.08;

    const rot = (Math.random() - 0.5) * 10 * Math.PI / 180;

    return <CoordDndWrapper skip={skip} attach>
        <mesh geometry={geo} material={mat} position={[x, y, z]} rotation-y={rot} />
    </CoordDndWrapper>
}

function usePieceMat(color?: string){
    const matcap = useLoader(TextureLoader, matcapCeramic);
    return suspend(async color => {
        return new MeshMatcapMaterial({
            color,
            matcap
        });
    }, [color, 'piecemat']);
}

function pilemax(i: number) {
    let m = 0;
    let n = 0;
    while(m < i){
        n += 1;
        m += n * n;
    }
    return [m, n] as Boardcode.Vec2;
}

function pilepos(i: number): Boardcode.Vec3{
    let m = 0;
    let n = 0;
    while(m < i){
        n += 1;
        m += n * n;
    }
    m -= n * n;
    const r = i - m - 1;
    const x = r % n;
    const z = (r - x) / n;
    const y = n - 1;
    return [x - y / 2, y, z - y / 2];
}