import React from 'react';
import { MqttClientCtx } from './mqtt';

export function MqttTest(){
    const ctx = React.useMemo(() => new MqttClientCtx('blah/'), []);

    ctx.useConnection();

    return <>
        <MqttPinger ctx={ctx} topic="obadi" />
        <MqttLabel ctx={ctx} topic="obadi" />
        <MqttLabel ctx={ctx} topic="obadu" />
    </>
}

function MqttPinger(props: { ctx: MqttClientCtx, topic: string }) {
    const { ctx, topic } = props;
    const [n, setN] = React.useState(0);

    React.useLayoutEffect(() => {
        let cancel = false;
        (async function(){
            let i = 1;
            while(!cancel){
                await new Promise(resolve => setTimeout(resolve, 2000));
                setN(i++);
                ctx.send(topic, `${i}`);
                console.log('sent', topic, i);
            }
        })();
        return function(){
            cancel = true;
        }
    }, [ctx, topic ,setN]);

    return <span>Ping {topic}: {n}</span>
}


function MqttLabel(props: { ctx: MqttClientCtx, topic: string }) {
    const { ctx, topic } = props;
    const msgs = ctx.useTopic(topic);

    ctx.useTopic(topic);

    React.useLayoutEffect(msgs.updateEffect(packet => {
        console.log('received', topic, ':', packet.payload);
    }), [msgs, topic]);

    return null;
}