import { parseDocCode } from "@ttws/boardcode";
import yaml from "js-yaml";
import React from "react";
import { suspend } from "suspend-react";
import { BoardcodeProvider, EachCoord, EachSeat, EachZone } from "../boardcode";
import { GLTFProps, GLTFScene } from "../r3f";
import { useRefState } from "../utils";
import { BoardcodeToolbar } from "./boardtools";
import { CoordParts } from "./coordparts";
import { MqttCommandHandler } from "./mqtt";
import { partRenderers } from "./parts";
import { PartTemplate } from "./parts/type";
import { BoardTableRig, SeatLabel, SeatTools, TableSeat, TableSeats, ZoneOutline } from "./table";
import { ToolDivider, ToolHud } from "./toolitem";

export type Boardspace = {
    title: string;

    tabletop: {
        scenes?: GLTFProps[],
        parts: {
            [key: string]: PartTemplate<any>
        },
        seats: TableSeat[]
    }

    boardcode: string;
}

function getParams<P extends { [key: string]: string }, A extends (keyof P)[]>(p: Partial<P>, ...keys: A) {
    const params = new URLSearchParams(location.search);
    return keys.map(key => p[key] || params.get(key as string) || undefined);
}

export function BoardspaceApp(props: { boardspace?: string, scope?: string, server?: string }) {
    const [boardspace, scope, server] = getParams(props, 'boardspace', 'scope', 'server');

    const { initDoc, parts, seats, scenes } = suspend(async () => {
        if (!boardspace) throw new Error("boardspace file not found.");

        const res = await fetch(boardspace);
        const txt = await res.text();

        const loadJson = yaml.load(txt) as Boardspace;

        const title = loadJson.title;
        if (title) document.title = title;

        const parts = loadJson?.tabletop?.parts;
        if (!parts) throw new Error(`part definition not found under "tabletop.parts"`);

        const seats = loadJson.tabletop?.seats;
        if (!seats) throw new Error(`seat definition not found under"tabletop.seats"`);

        const initDoc = parseDocCode(loadJson.boardcode);
        for (const seatKey of Object.keys(initDoc.layout.seats)) {
            const seat = initDoc.layout.seats[seatKey];
            for(const zoneKey of Object.keys(seat.zones)){
                const path = `${seatKey}/${zoneKey}`;

                initDoc.content.zones[path] = initDoc.content.zones[path] || {
                    zonePath: path,
                    coords: []
                };
            }
        }

        return {
            initDoc,
            parts,
            seats,
            scenes: loadJson.tabletop?.scenes
        }
    }, [boardspace]);

    const doc = useRefState(initDoc);

    return <BoardcodeProvider doc={doc}>
        {scope && <MqttCommandHandler scope={scope} server={server}/>}
        <BoardTableRig>
            <EachSeat>
                <SeatLabel/>
                <EachZone>
                    <ZoneOutline/>
                    <EachCoord>
                        <CoordParts renderers={partRenderers} templates={parts} />
                    </EachCoord>
                </EachZone>
            </EachSeat>
            <TableSeats seats={seats}/>
            {scenes && scenes.map(scene => <GLTFScene key={scene.src} {...scene} />)}
        </BoardTableRig>
        <ToolHud>
            <BoardcodeToolbar />
            <ToolDivider />
            <SeatTools />
        </ToolHud>
    </BoardcodeProvider>
}
