import { RootState, useThree } from "@react-three/fiber";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { Boardcode } from "@ttws/boardcode";
import React from "react";
import { Plane, Vector3 } from "three";
import { PointerContext, pointerDispatchCtx, PointerState, Selector } from "../utils";

export class R3FPointerState extends PointerState {
    groundPlane = new Plane(new Vector3(0, 1, 0));
    groundPos = new Vector3();
    three?: RootState;

    targetOffset = new Vector3();
    castDown = this.createCastedSelector(this.down);
    castDrag = this.createCastedSelector(this.drag);
    castMovement = new Selector((down, drag) => {
        if (!down || !drag) return null;
        drag = drag || down;
        return [drag[0] - down[0], drag[1] - down[1], drag[2] - down[2]] as Boardcode.Vec3;
    }, this.castDown, this.castDrag);

    useR3F() {
        this.castDown.useSelectByRef();
        this.castDrag.useSelectByRef();
        this.castMovement.useSelectByRef();

        this.three = useThree();
        const self = this;
        React.useLayoutEffect(() => {
            return function () {
                self.three = undefined;
            }
        }, []);
    }

    createCastedSelector(pointer: typeof this.down) {
        return new Selector(e => {
            if (!e || !this.three) return null;
            const { x, y, z } = this.castToGround(e, this.three);
            return [x, y, z] as Boardcode.Vec3;
        }, pointer);
    }

    castToGround(event: PointerEvent, state: RootState) {
        const x = event.clientX - state.gl.domElement.clientLeft;
        const y = event.clientY - state.gl.domElement.clientTop;
        state.pointer.set((x / state.size.width) * 2 - 1, -(y / state.size.height) * 2 + 1);
        state.raycaster.setFromCamera(state.pointer, state.camera);
        state.raycaster.ray.intersectPlane(this.groundPlane, this.groundPos);
        return this.groundPos;
    }

    bind(ctx?: PointerContext): EventHandlers {
        ctx = ctx || React.useContext(pointerDispatchCtx);
        return {
            onPointerEnter: e => {
                this.onPointerEnter(e.nativeEvent);
            },
            onPointerLeave: e => {
                this.onPointerLeave(e.nativeEvent);
            },
            onPointerDown: e => {
                this.targetOffset.set(0, 0, 0).applyMatrix4(e.eventObject.matrixWorld).sub(e.point);
                this.onPointerDown(e.nativeEvent, ctx!);
                e.stopPropagation();
            }
        }
    }
}