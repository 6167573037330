import { useLoader } from "@react-three/fiber";
import { suspend } from 'suspend-react';
import { Mesh, MeshBasicMaterial, MeshMatcapMaterial, RepeatWrapping, sRGBEncoding, TextureLoader } from "three";
import { GLTFLoader } from "three-stdlib";
import { applyUVOffset, CardEdgeGeometry, CardGeometry, makeEdgeGeometry } from "./cardgeometry";

export const matcapCeramic = "ceramic.jpg";
export const matcapWhite = "white.png";
export const normalPaper = "normal.jpg";
export const glbParts = "parts.glb";

export const playerColors = {
    red: '#b4141e',
    blue: '#0042ff',
    teal: '#1ca7ea',
    purple: '#540081',
    yellow: '#ebe129',
    orange: '#fe8a0e',
    green: '#168000',
}

export const basicMaterials = {
    white: new MeshBasicMaterial({ color: 'white', transparent: true, depthWrite: false  }),
    yellow: new MeshBasicMaterial({ color: 'yellow', transparent: true, depthWrite: false }),
    red: new MeshBasicMaterial({ color: 'red', transparent: true, depthWrite: false }),
}

export const cardEdges = {
    card5486: new CardEdgeGeometry(5.4, 8.6, 0.3, 1, 5).rotateX(-Math.PI / 2),
    card6389: new CardEdgeGeometry(6.35, 8.89, 0.3, 1, 5).rotateX(-Math.PI / 2),
}
export const cardGeos = {
    card5486: new CardGeometry(5.4, 8.6, 0.3, 5).rotateX(-Math.PI / 2),
    card6389: new CardGeometry(6.35, 8.89, 0.3, 5).rotateX(-Math.PI / 2),
}

export type PartAssetNodes = {
    card5486: Mesh,
    card6389: Mesh,

    church: Mesh,
    house1: Mesh,
    house2: Mesh,

    meeple: Mesh,
    pawn1: Mesh,
    pawn2: Mesh,

    cube: Mesh,
    cylinder: Mesh,
    coin: Mesh,
    hexagon: Mesh,
    go: Mesh,
}

export function usePartAssets<T extends { [key: string]: Mesh } = PartAssetNodes>(src = glbParts) {
    const gltf = useLoader(GLTFLoader, src) as unknown as any;
    return gltf.nodes as T;
}

export function usePartGeometry(partType: keyof PartAssetNodes, sedge: number, uvOffset?: [number, number], uvScale?: [number, number]) {
    const nodes = usePartAssets();
    const offset = sedge ? [0,0] : uvOffset || [0, 0];
    const scale = sedge ? [1,1] : uvScale || [1, 1];
    return suspend(async (type = partType, edge = sedge, ou = offset[0], ov = offset[1], su = scale[0], sv = scale[1]) => {
        // console.log('creating part', { type, edge, ou, ov, su, sv });
        
        let geo = type in cardGeos ? cardGeos[type as keyof typeof cardGeos] : nodes[type].geometry;

        if(edge){
            const edgekey = `${type}edge` as typeof type;
            if (edgekey in nodes) geo = nodes[edgekey].geometry;
            else geo = makeEdgeGeometry(geo, edge, 1);
            return geo;
        }

        if(ou !== 0 || ov !== 0 || su !== 1 || sv !== 1) {
            geo = geo.clone();
            applyUVOffset(geo, ou, ov, su, sv);
        }

        return geo;
    }, [partType, sedge, offset[0], offset[1], scale[0], scale[1]]);
}

export function usePartMaterial(sprint?: string, smatcap?: string, scolor?: string, snormal?: string) {
    const printTexture = useLoader(TextureLoader, sprint || matcapCeramic);
    const matcapTexture = useLoader(TextureLoader, smatcap || (sprint ? matcapWhite : matcapCeramic));
    const normalTexture = useLoader(TextureLoader, snormal || matcapCeramic);

    return suspend(async (print = sprint, matcap = smatcap, color = scolor, normal = snormal) => {
        const mat = new MeshMatcapMaterial({
            matcap: matcapTexture,
            color: color || 'white',
            normalMap: normal ? normalTexture : null,
        });
        if(normal){
            normalTexture.repeat.set(0.01, 2);
            normalTexture.wrapS = normalTexture.wrapT = RepeatWrapping;
        }
        if(print){
            printTexture.encoding = sRGBEncoding;
            mat.map = printTexture;
            mat.map.anisotropy = 4;
            mat.map.flipY = !mat.map.flipY;
        }

        // console.log('created material', {color, print});
        return mat;
    }, [sprint, smatcap, scolor, snormal]);
}