import { cardRenderer } from "./card";
import { pieceRenderer } from "./piece";
import { trackerRenderer } from "./tracker";

export { PartList } from "./partlist";

export const partRenderers = {
    card: cardRenderer,
    piece: pieceRenderer,
    tracker: trackerRenderer
};